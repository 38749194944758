import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { Formik } from 'formik';
import * as Yup from 'yup';

import SEO from '../components/seo';
import { Section, TwoCols, TwoCol } from '../components/page-elements';
import Container from '../components/container';
import PrimaryHeaderBlock from '../components/primaryHeaderBlock';
import BottomCut from '../components/bottomCut';
import Button from '../components/buttons';
import MiniPoint from '../components/_miniPoint';
import LargeTestimonial from '../components/largeTestimonial';
import CTABlock from '../components/ctaBlock';
import Spacer from '../components/spacer';
import { PreHeader, ButtonText } from '../theme/typography';
import FormInput2 from '../components/formInput2';
import AnimatedStripe from '../components/animatedStripe';

// Asset Imports
import Stripes05SVG from '../images/stripes/stripes05.inline.svg';
import Stripes03SVG from '../images/stripes/stripes03.inline.svg';

//Styled Components
const FormCol = styled(TwoCol)`
  @media (min-width: 1024px) {
    align-items: flex-end;
  }
`;

const FormWrapper = styled.div`
  width: 100%;
  max-width: 500px;
  z-index: 1;
  position: relative;
  overflow: visible;

  @media (min-width: 1024px) {
    width: 500px;
    max-width: 95%;
    position: absolute;
  }
`;

const FormTile = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 48px 32px 64px 32px;
  background: var(--blue6);
  border-radius: 8px;
  box-shadow: var(--box-shadow-xl);
`;

const FormTitle = styled(PreHeader)`
  margin: 0 auto 32px auto;
`;

const FormStripes01 = styled(AnimatedStripe)`
  position: absolute;
  width: 500px;
  height: 350px;
  top: -30px;
  left: -200px;
  z-index: -1;

  @media (min-width: 1024px) {
    width: 450px;
    height: 300px;
    top: -30px;
    left: -80px;
  }

  @media (min-width: 1300px) {
    left: -190px;
    top: -60px;
    width: 500px;
    height: 350px;
  }
`;

const FormStripes02 = styled(AnimatedStripe)`
  position: absolute;
  width: 70%;
  height: 20%;
  bottom: -5%;
  right: -25%;
  z-index: -1;
`;

const RadioRow = styled.div`
  display: flex;

  flex-direction: column;
  gap: 16px;

  @media (min-width: 500px) {
    flex-direction: row;
    gap: 24px;
  }
`;

const InputLabel = styled(ButtonText)`
  margin-bottom: 4px;
  color: var(--blue2);
`;

const MiniPointContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 48px;

  > div {
    margin: 0 auto;
  }
  @media (min-width: 800px) {
    > div {
      margin: 0;
    }
  }
  @media (min-width: 1024px) {
    max-width: 50%;
  }

  @media (min-width: 1440px) {
    gap: 80px;
  }
`;

const SendMessageButton = styled(Button)`
  width: 100%;

  &:disabled {
    background-color: var(--blue4);
    opacity: 0.6;
    cursor: unset;

    &:hover {
      transform: none;
      box-shadow: none;
    }
  }
`;

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, `C'mon your name is longer than that!`)
    .required(`Required`),
  lastName: Yup.string().required(`Required`),
  email: Yup.string().email(`Must be a valid email.`).required(`Required`),
  phone: Yup.string()
    .matches(
      /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/,
      `Must be a valid phone number.`,
    )
    .required(`Required`),
  relationship: Yup.string(),
  portfolio: Yup.string()
    .matches(
      /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm,
      `Must be a valid url`,
    )
    .required(`Required`),
  message: Yup.string(),
});

const DirectorSetupPage = ({ data }) => {
  const {
    headerBlock,
    miniPoint1,
    miniPoint2,
    miniPoint3,
    miniPoint4,
    rosteredBackgroundImage,
    freelanceBackgroundImage,
    featuredTestimonial,
    ctaBlock,
  } = data.sanityJoinTheFlockPage;
  return (
    <>
      <SEO title='Join the Flock' />
      <Helmet>
        <body className='dark-nav' />
      </Helmet>
      <Section
        dark
        style={{ marginTop: 'var(--site-header-height)', overflowX: 'clip' }}
      >
        <Container>
          <TwoCols>
            <TwoCol>
              <PrimaryHeaderBlock data={headerBlock} />
            </TwoCol>
            <FormCol>
              <FormWrapper>
                <FormStripes01 direction='leftAndUp'>
                  <Stripes05SVG />
                </FormStripes01>
                <FormTile>
                  <FormTitle>Application Form</FormTitle>
                  <Formik
                    initialValues={{
                      'form-name': `Director Application Form`,
                      'bot-field': ``,
                      firstName: ``,
                      lastName: ``,
                      email: ``,
                      phone: ``,
                      relationship: `unselected`,
                      portfolio: ``,
                      message: ``,
                    }}
                    validationSchema={validationSchema}
                  >
                    {({
                      handleChange,
                      handleBlur,
                      values,
                      errors,
                      touched,
                      isValid,
                    }) => (
                      <form
                        name='Director Application Form'
                        method='POST'
                        data-netlify='true'
                        data-netlify-honeypot='bot-field'
                        action='/success/'
                      >
                        {/* The `form-name` hidden field is required to support form
                submissions in Netlify */}
                        <input
                          type='hidden'
                          name='form-name'
                          value='Director Application Form'
                        />
                        <p hidden>
                          Don’t fill this out if you're a human:
                          <input name='bot-field' />
                        </p>
                        <FormInput2
                          name='firstName'
                          label='First Name'
                          placeholder='John'
                          required
                          value={values.firstName}
                          onChange={handleChange('firstName')}
                          onBlur={handleBlur('firstName')}
                          errorCase={!!errors.firstName && touched.firstName}
                          errorMsg={errors.firstName}
                        />
                        <Spacer size={24} />
                        <FormInput2
                          name='lastName'
                          label='Last Name'
                          placeholder='Murphy'
                          required
                          value={values.lastName}
                          onChange={handleChange('lastName')}
                          onBlur={handleBlur('lastName')}
                          errorCase={!!errors.lastName && touched.lastName}
                          errorMsg={errors.lastName}
                        />
                        <Spacer size={24} />
                        <FormInput2
                          name='email'
                          label='Email'
                          placeholder='johnmurphy@gmail.com'
                          required
                          value={values.email}
                          onChange={handleChange('email')}
                          onBlur={handleBlur('email')}
                          errorCase={!!errors.email && touched.email}
                          errorMsg={errors.email}
                        />
                        <Spacer size={24} />
                        <FormInput2
                          name='phone'
                          label='Phone'
                          placeholder='0123456789'
                          required
                          value={values.phone}
                          onChange={handleChange('phone')}
                          onBlur={handleBlur('phone')}
                          errorCase={!!errors.phone && touched.phone}
                          errorMsg={errors.phone}
                        />
                        <Spacer size={24} />
                        <fieldset>
                          <InputLabel as='legend'>Relationship</InputLabel>
                          <RadioRow>
                            <FormInput2
                              name='relationship'
                              label='Relationship'
                              radio
                              value='Full Irish Representation'
                              radioBackground={rosteredBackgroundImage}
                              onChange={handleChange('relationship')}
                              onBlur={handleBlur('relationship')}
                            />
                            <FormInput2
                              name='relationship'
                              label='Relationship'
                              radio
                              value='Freelance ‘Friend of the Flock’'
                              radioBackground={freelanceBackgroundImage}
                              onChange={handleChange('relationship')}
                              onBlur={handleBlur('relationship')}
                            />
                          </RadioRow>
                        </fieldset>
                        <Spacer size={24} />
                        <FormInput2
                          name='portfolio'
                          label='Portfolio Link'
                          placeholder='www.google.com'
                          required
                          value={values.portfolio}
                          onChange={handleChange('portfolio')}
                          onBlur={handleBlur('portfolio')}
                          errorCase={!!errors.portfolio && touched.portfolio}
                          errorMsg={errors.portfolio}
                        />
                        <Spacer size={24} />
                        <FormInput2
                          name='message'
                          label='Message'
                          multiline
                          value={values.message}
                          onChange={handleChange('message')}
                          onBlur={handleBlur('message')}
                          errorCase={!!errors.message && touched.message}
                          errorMsg={errors.message}
                        />
                        <Spacer size={48} />
                        <SendMessageButton
                          type='submit'
                          disabled={!isValid}
                          label='Submit'
                          arrow
                        />
                      </form>
                    )}
                  </Formik>
                </FormTile>
                <FormStripes02 direction='left'>
                  <Stripes03SVG style={{ transform: `scaleX(-1)` }} />
                </FormStripes02>
              </FormWrapper>
            </FormCol>
          </TwoCols>
        </Container>
        <BottomCut light />
      </Section>
      <Section light cuts='topCut'>
        <Container>
          <MiniPointContainer>
            <MiniPoint data={miniPoint1} />
            <MiniPoint data={miniPoint2} />
            <MiniPoint data={miniPoint3} />
            <MiniPoint data={miniPoint4} />
          </MiniPointContainer>
        </Container>
      </Section>
      <Section light cuts='extSectBottomCut'>
        <Container>
          <LargeTestimonial data={featuredTestimonial} />
          <Spacer size={144} />
          <CTABlock data={ctaBlock} />
          <Spacer size={80} />
        </Container>
        <BottomCut dark />
      </Section>
    </>
  );
};

export const joinPageQuery = graphql`
  {
    sanityJoinTheFlockPage {
      headerBlock {
        ...SanityPrimaryHeaderBlockFragment
      }
      miniPoint1 {
        ...SanityMiniPointFragment
      }
      miniPoint2 {
        ...SanityMiniPointFragment
      }
      miniPoint3 {
        ...SanityMiniPointFragment
      }
      miniPoint4 {
        ...SanityMiniPointFragment
      }
      featuredTestimonial {
        author
        quote
        quoteHighlight
        client {
          svgLogo
        }
        jobTitle
      }
      rosteredBackgroundImage {
        ...SanityImageFragment
      }
      freelanceBackgroundImage {
        ...SanityImageFragment
      }
      ctaBlock {
        ...SanityCTABlockFragment
      }
    }
  }
`;

export default DirectorSetupPage;
