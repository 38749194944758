import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

//Component Imports
import { D3, P2 } from '../theme/typography';
import Button from './buttons';

const PointContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 207px;
`;

const IconBubble = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: var(--icon-bubble-fill);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
`;

const IconSVG = styled.svg`
  width: 24px;
  height: 24px;
  stroke: var(--blue1);
  fill: none;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
`;

const Title = styled(D3)`
  color: var(--primary-text);
  margin-bottom: 16px;
`;

const Body = styled(P2)`
  color: var(--primary-text);
  margin-bottom: 16px;
`;
const MiniPoint = ({ data }) => {
  const { icon, title, body, cta, ctaData } = data;
  return (
    <PointContainer>
      <IconBubble>
        <IconSVG>
          <use href={`/icons/feather-sprite.svg#${icon.name}`} />
        </IconSVG>
      </IconBubble>
      <Title>{title}</Title>
      <Body>{body}</Body>
      {cta && (
        <Button
          label={cta && ctaData.label ? ctaData.label : 'More Info'}
          type={cta && ctaData.type ? ctaData.type : 'link'}
          arrow={cta && ctaData.arrow ? true : false}
          path={cta && ctaData.path}
        />
      )}
    </PointContainer>
  );
};

export default MiniPoint;
