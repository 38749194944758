import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

//Component Imports
import { HeroPreHeader, D1, P1 } from '../theme/typography';
import Button from './buttons';
import VideoModal from './videoModal';

//Styled Components
const TextBlock = styled.div`
  color: var(--primary-text);
  max-width: 435px;
  text-align: center;

  @media (min-width: 1024px) {
    text-align: left;
  }
`;

const SecondaryHeader = styled(HeroPreHeader)`
  margin-bottom: 4px;
  color: var(--secondary-text);
`;

const PrimaryHeader = styled(D1)`
  margin-bottom: 24px;
  text-transform: capitalize;
  color: var(--primary-text);
`;

const BodyText = styled(P1)`
  margin-bottom: ${({ hasButtons }) => (hasButtons ? '48px' : '0')};
  color: var(--primary-text);
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  white-space: pre-wrap;

  @media (min-width: 1024px) {
    padding-right: 32px;
    max-width: 95%;
    margin-left: unset;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;

  @media (min-width: 500px) {
    flex-direction: row;
    justify-content: center;
  }

  @media (min-width: 1024px) {
    justify-content: flex-start;
  }
`;

const ModalWrapper = styled.div`
  /* Take it out of document flow */
  position: absolute;
  /* Just to overwrite the modal video plugins styles */
  .modal-video-movie-wrap {
    background: none;
  }

  .modal-video {
    background-color: rgba(0, 0, 0, 0.9);
  }

  .modal-video-close-btn {
    cursor: pointer;
  }
`;

const PrimaryHeaderBlock = ({ data, ...props }) => {
  const {
    preHeader,
    title,
    body,
    primaryCTA,
    primaryCTAData,
    secondaryCTA,
    secondaryCTAData,
  } = data;
  console.log(secondaryCTAData);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = useCallback(
    () => setIsModalOpen((prevState) => !prevState),
    [],
  );

  return (
    <TextBlock {...props}>
      <SecondaryHeader>{preHeader}</SecondaryHeader>
      <PrimaryHeader>{title}</PrimaryHeader>
      <BodyText hasButtons={primaryCTA || secondaryCTA}>{body}</BodyText>
      <ButtonContainer>
        {primaryCTA && (
          <Button
            label={primaryCTAData.label}
            type={primaryCTAData.type ? primaryCTAData.type : 'primary'}
            arrow={primaryCTAData.arrow ? true : false}
            path={primaryCTAData.path ? primaryCTAData.path : ''}
            state={primaryCTAData.state && primaryCTAData.state}
          />
        )}
        {secondaryCTA && !secondaryCTAData.videoModal && (
          <Button
            label={secondaryCTAData.label}
            type={secondaryCTAData.type ? secondaryCTAData.type : 'link'}
            arrow={secondaryCTAData.arrow ? true : false}
            path={primaryCTAData.path ? primaryCTAData.path : ''}
          />
        )}
        {secondaryCTA && secondaryCTAData.videoModal && (
          <>
            <Button
              label={secondaryCTAData.label}
              type={secondaryCTAData.type ? secondaryCTAData.type : 'link'}
              arrow={secondaryCTAData.arrow ? true : false}
              onClick={toggleModal}
            />
            <VideoModal
              video={secondaryCTAData.video}
              isModalOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
            />
          </>
        )}
      </ButtonContainer>
    </TextBlock>
  );
};

PrimaryHeaderBlock.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    preHeader: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    primaryCTA: PropTypes.bool,
    primaryCTAData: PropTypes.object,
    secondaryCTA: PropTypes.bool,
    secondaryCTAData: PropTypes.object,
  }),
};

export default PrimaryHeaderBlock;
